<template>
    <comp-table title="供应商" ref="comp_table" :columns="columns" :table-api="tableApi" :operate="getTableOperate">
    </comp-table>
</template>

<script>
import CompTable from "../../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            tableApi: "/gateway/api/sycompany/pc/operator/selectShopPage?shopCat=" + "1" + "&operatorId=" + this.$route.query.operatorId,

            columns: [
                {
                    title: "运营商名称",
                    key: "shopName",
                    minWidth: 200,
                    align: "center",
                },
            ],
        }
    },

    methods: {
        getTableOperate(h, params) {
            return [
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                this.$post("/gateway/api/sycompany/pc/operator/deleteBindShop", {
                                    operatorShopId: params.row.operatorShopId
                                }).then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success("解绑成功")
                                        this.$refs.comp_table.onSearch()
                                    }
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },"解绑"
                ),
            ]
        },
    },
}
</script>
<style lang="less"></style>
